<template>
  <div class="u-display-flex u-flex-align-items-center">
    <div
      v-for="(item, index) of state"
      :key="index"
      class="u-display-flex u-flex-align-items-center"
      :class="{ 'u-spacing-ml-l': index }"
    >
      <span
        class="u-spacing-mr-xs u-flex-align-self-center circle"
        :class="`u-bg-color-${item.circleColor}-base`"
      />
      <span class="u-spacing-mr-xs u-color-grey-lighter u-font-size-6">
        {{ item.title }} :
      </span>
      <span
        class="u-spacing-mr-xs u-color-grey-light u-font-size-6 u-font-weight-600"
      >
        {{ item.count }}
      </span>
      <span class="u-color-grey-light u-font-size-6">
        {{ item.entity }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    state: {
      type: Array,
      default: () => []
    },
    tooltipText: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="css" scoped>
.circle {
  border-radius: 50%;
  height: 1.2rem;
  width: 1.2rem;
}
</style>
